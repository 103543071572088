<!-- <template>
  <div>
    <span class="position-fixed fixed-top d-flex justify-content-center w-100 
    animate__animated animate__slow animate__fadeInLeft">
      
      <div class="w-50">
        
        <div class="w-auto d-flex justify-content-center rounded text-dark fw-bold ms-auto m-1">
          <div class="w-auto px-3" style="font-size: 70px;">
            {{ terrainInfo.fullName }}
          </div>
          <div class="w-auto px-3" style="font-size: 70px;">
            {{ matche.startTime }}
          </div>
          
          <div class="w-auto px-3" style="font-size: 70px;">
            {{ matche.endTime }}
          </div>
        </div>
      </div>
      
    </span>
    <div class="text-center ">

      <div v-if="loading">
        <Loading />
      </div>

      <div v-else>

        <div v-if="matche==-1">
          <MatchActiveEmpty  />
        </div>

        <div v-else-if="matche.is_match_not_started==true">
          <MatchNotStarted :teamNameA="matche.fullNameTeamA" :teamNameB="matche.fullNameTeamB" />
        </div>

        <div v-else-if="matche.is_match_finished==true">
          <MatchFinished :teamNameA="matche.fullNameTeamA" :teamNameB="matche.fullNameTeamB" />
        </div>

        <div v-else-if="showGoalAlert">
          <GoalAlert :teamName="alertTeamName" />
        </div>

        <div v-else-if="showCanceledGoalAlert">
          <CancelGoal :teamName="alertTeamName" />
        </div>

        <div v-else-if="matche!=-1" class="fw-bold d-flex justify-content-center align-items-center h-100"
          >
          <div :style="{ fontSize: matche.goalsTeamA < 10 ? '550px' : '350px' }"
          class="bg-primary text-white text-center rounded w-100 h-100
          animate__animated animate__slow animate__fadeInLeft p-2">

            <span style="font-size: 70px;line-height: 110px;" class=" bg-primary  text-white rounded mx-auto d-flex justify-content-center">
              {{ matche.fullNameTeamA }}
            </span>
            <div class=" " :style="{ lineHeight: matche.goalsTeamA < 10 ? '465px' : '355px' }">
              {{ matche.goalsTeamA }}
            </div>
          </div> 
          <span class="mx-5">
            <div v-if="matche" >
              <ReservationCard :reservation="matche"/>
            </div>
          </span>

          <div :style="{ fontSize: matche.goalsTeamA < 10 ? '550px' : '350px' }"
          class="bg-dark text-white rounded w-100 h-100
          animate__animated animate__slow animate__fadeInRight p-2">
            <span style="font-size: 70px;line-height: 110px;" class=" bg-dark text-white rounded mx-auto d-flex justify-content-center">
              {{ matche.fullNameTeamB }}
            </span>
            <div class=" " :style="{ lineHeight: matche.goalsTeamA < 10 ? '465px' : '355px' }">
              {{ matche.goalsTeamB }}
            </div>
          </div>
        </div> 

        
      </div>
    </div>
    
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import GoalAlert from "./GoalAlert.vue";
import CancelGoal from "./CancelGoal.vue";
import MatchFinished from "./MatchFinished.vue";
import MatchNotStarted from "./MatchNotStarted.vue";
import MatchActiveEmpty from "./MatchActiveEmpty.vue";
import ReservationCard from "./ReservationCard.vue";
import Loading from "./Loading.vue";

export default {
  props: {
    terrain: {
      type: String,
      required: true
    }
  },
  components: {
    GoalAlert,
    CancelGoal,
    ReservationCard,
    MatchFinished,
    MatchNotStarted,
    MatchActiveEmpty,
    Loading
  },
  data() {
    return {
      totalMinutes: 0,
      timer: {
        minutes: 0,
        seconds: 0,
      },
      interval: null,
      previousGoalsTeamA: 0,
      previousGoalsTeamB: 0,
      showGoalAlert: false,
      showCanceledGoalAlert: false,
      alertTeamName: '',
      loading: true,
    };
  },
  computed: {
    ...mapGetters("matche", {
      matche: "getMatche",
    }),
    ...mapGetters("terrain", {
      terrainInfo: "getTerrain",
    }),
  },
  watch: {
    matche: {
      handler(newMatche) {
        if (newMatche.goalsTeamA > this.previousGoalsTeamA) {
          this.alertTeamName = this.matche.fullNameTeamA;
          this.triggerGoalAlert();
        } else if (newMatche.goalsTeamB > this.previousGoalsTeamB) {
          this.alertTeamName = this.matche.fullNameTeamB;
          this.triggerGoalAlert();
        }

        if (newMatche.goalsTeamA < this.previousGoalsTeamA) {
          this.alertTeamName = this.matche.fullNameTeamA;
          this.triggerCanceledGoalAlert();
        } else if (newMatche.goalsTeamB < this.previousGoalsTeamB) {
          this.alertTeamName = this.matche.fullNameTeamB;
          this.triggerCanceledGoalAlert();
        }

        this.previousGoalsTeamA = newMatche.goalsTeamA;
        this.previousGoalsTeamB = newMatche.goalsTeamB;

        // Set loading to false once data is available
        this.loading = false;
      },
      deep: true,
    },
  },
  methods: {
    triggerGoalAlert() {
      this.showGoalAlert = true;
      setTimeout(() => {
        this.showGoalAlert = false;
      }, 8000);
    },
    triggerCanceledGoalAlert() {
      this.showCanceledGoalAlert = true;
      setTimeout(() => {
        this.showCanceledGoalAlert = false;
      }, 8000);
    },
  },
  mounted() {
    this.previousGoalsTeamA = this.matche.goalsTeamA;
    this.previousGoalsTeamB = this.matche.goalsTeamB;

    this.interval = setInterval(() => {
      this.$store.dispatch("matche/show", this.terrain);
    }, 5000);
    this.$store.dispatch("terrain/show", this.terrain);


    Echo.channel('matches')
        .listen('MatchUpdated', (event) => {
            this.$store.commit('matche/SET_MATCHE', event.match);
        });
  },
  beforeDestroy() {
    clearInterval(this.interval);
  },
};
</script>

<style scoped>
/* Add any scoped styles you need here */
</style> -->
<template>
  <div>
    <span class="position-fixed fixed-top d-flex justify-content-center w-100 
    animate__animated animate__slow animate__fadeInLeft">
      <div class="w-50">
        <div class="w-auto d-flex justify-content-center rounded text-dark fw-bold ms-auto m-1">
          <div class="w-auto px-3" style="font-size: 70px;">
            {{ terrainInfo.fullName }}
          </div>
          <div class="w-auto px-3" style="font-size: 70px;">
            {{ matche.startTime }}
          </div>
          <div class="w-auto px-3" style="font-size: 70px;">
            {{ matche.endTime }}
          </div>
        </div>
      </div>
    </span>
    <div class="text-center">

      <!-- Alert match not loading yet -->
      <div v-if="loading">
        <Loading />
      </div>

      <div v-else>

        <!-- Alert match finished -->
        <div v-if="matche == -1">
          <MatchActiveEmpty />
        </div>

        <!-- Alert match not started -->
        <div v-else-if="matche.is_match_not_started == true">
          <MatchNotStarted :teamNameA="matche.fullNameTeamA" :teamNameB="matche.fullNameTeamB" />
        </div>

        <!-- Alert match finished -->
        <div v-else-if="matche.is_match_finished == true">
          <MatchFinished :teamNameA="matche.fullNameTeamA" :teamNameB="matche.fullNameTeamB" />
        </div>

        <!-- Alert for goal -->
        <div v-else-if="showGoalAlert">
          <GoalAlert :teamName="alertTeamName" />
        </div>

        <!-- Alert for canceled goal -->
        <div v-else-if="showCanceledGoalAlert">
          <CancelGoal :teamName="alertTeamName" />
        </div>

        <!-- Goals show -->
        <div v-else-if="matche != -1" class="fw-bold d-flex justify-content-center align-items-center h-100">
          <div :style="{ fontSize: matche.goalsTeamA < 10 ? '550px' : '350px' }"
          class="bg-primary text-white text-center rounded w-100 h-100 animate__animated animate__slow animate__fadeInLeft p-2">

            <span style="font-size: 70px; line-height: 110px;" class="bg-primary text-white rounded mx-auto d-flex justify-content-center">
              {{ matche.fullNameTeamA }}
            </span>
            <div :style="{ lineHeight: matche.goalsTeamA < 10 ? '465px' : '355px' }">
              <!-- {{ formatGoals(matche.goalsTeamA) }} -->
              {{ (matche.goalsTeamA) }}
            </div>
          </div> 
          
          <span class="mx-5">
            <div v-if="matche">
              <ReservationCard :reservation="matche" />
            </div>
          </span>

          <div :style="{ fontSize: matche.goalsTeamA < 10 ? '550px' : '350px' }"
          class="bg-dark text-white rounded w-100 h-100 animate__animated animate__slow animate__fadeInRight p-2">
            <span style="font-size: 70px; line-height: 110px;" class="bg-dark text-white rounded mx-auto d-flex justify-content-center">
              {{ matche.fullNameTeamB }}
            </span>
            <div :style="{ lineHeight: matche.goalsTeamB < 10 ? '465px' : '355px' }">
              <!-- {{ formatGoals(matche.goalsTeamB) }} -->
              {{ (matche.goalsTeamB) }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import GoalAlert from "./GoalAlert.vue";
import CancelGoal from "./CancelGoal.vue";
import MatchFinished from "./MatchFinished.vue";
import MatchNotStarted from "./MatchNotStarted.vue";
import MatchActiveEmpty from "./MatchActiveEmpty.vue";
import ReservationCard from "./ReservationCard.vue";
import Loading from "./Loading.vue";

export default {
  props: {
    terrain: {
      type: String,
      required: true
    }
  },
  components: {
    GoalAlert,
    CancelGoal,
    ReservationCard,
    MatchFinished,
    MatchNotStarted,
    MatchActiveEmpty,
    Loading
  },
  data() {
    return {
      totalMinutes: 0,
      timer: {
        minutes: 0,
        seconds: 0,
      },
      interval: null,
      previousGoalsTeamA: 0,
      previousGoalsTeamB: 0,
      showGoalAlert: false,
      showCanceledGoalAlert: false,
      alertTeamName: '',
      loading: true,
    };
  },
  computed: {
    ...mapGetters("matche", {
      matche: "getMatche",
    }),
    ...mapGetters("terrain", {
      terrainInfo: "getTerrain",
    }),
  },
  watch: {
    matche: {
      handler(newMatche) {
        if (newMatche.goalsTeamA > this.previousGoalsTeamA) {
          this.alertTeamName = this.matche.fullNameTeamA;
          this.triggerGoalAlert();
        } else if (newMatche.goalsTeamB > this.previousGoalsTeamB) {
          this.alertTeamName = this.matche.fullNameTeamB;
          this.triggerGoalAlert();
        }

        if (newMatche.goalsTeamA < this.previousGoalsTeamA) {
          this.alertTeamName = this.matche.fullNameTeamA;
          this.triggerCanceledGoalAlert();
        } else if (newMatche.goalsTeamB < this.previousGoalsTeamB) {
          this.alertTeamName = this.matche.fullNameTeamB;
          this.triggerCanceledGoalAlert();
        }

        this.previousGoalsTeamA = newMatche.goalsTeamA;
        this.previousGoalsTeamB = newMatche.goalsTeamB;

        // Set loading to false once data is available
        this.loading = false;
      },
      deep: true,
    },
  },
  methods: {
    triggerGoalAlert() {
      this.showGoalAlert = true;
      setTimeout(() => {
        this.showGoalAlert = false;
      }, 8000);
    },
    triggerCanceledGoalAlert() {
      this.showCanceledGoalAlert = true;
      setTimeout(() => {
        this.showCanceledGoalAlert = false;
      }, 8000);
    },
    // Method to format the goals as two digits (e.g., 03 instead of 3)
    formatGoals(goals) {
      return String(goals).padStart(2, '0');
    }
  },
  mounted() {
    this.previousGoalsTeamA = this.matche.goalsTeamA;
    this.previousGoalsTeamB = this.matche.goalsTeamB;

    this.interval = setInterval(() => {
      this.$store.dispatch("matche/show", this.terrain);
    }, 5000);
    this.$store.dispatch("terrain/show", this.terrain);

    Echo.channel('matches')
        .listen('MatchUpdated', (event) => {
            this.$store.commit('matche/SET_MATCHE', event.match);
        });
  },
  beforeDestroy() {
    clearInterval(this.interval);
  },
};
</script>

<style scoped>
/* Add any scoped styles you need here */
</style>
